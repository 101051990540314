@import '../../variable.scss';

.settings {
  &_sub_title {
    font-size: 10px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $hit_gray;
    margin-bottom: 0;
  }
  &_main_title {
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    color: #3f4c51;
    margin-bottom: 10px;
  }
  &_tabs {
    &.nav.nav-tabs {
      padding: 0 10px;
      .nav-item {
        min-width: 180px;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        color: $white;
        margin-right: 17px;
        background-color: $primary_color;
        border-radius: 0;
        &.active {
          background-color: $white;
          font-weight: 700;
          color: $primary_color;
        }
        &:hover{
          border-color: transparent;
        }
      }
    }
  }
}

.beta-img {
  position: absolute;
  width: 40px;
  top: 56px;
  left: 11px;
}