@import "../../variable.scss";

@mixin base($bg, $color) {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 780px;
  background-color: $bg;
  color: $color;
  font-weight: 400;
  font-size: 16px;
  padding: 13px 15px;
  margin-bottom: 10px;
  .successMessage {
    padding-left: 10px;
  }
}

.alert_component {
  &__alert {
    @include base($bg: $alert_danger, $color: $danger_hover_color);
  }

  &__warning {
    @include base($bg: $light_peach, $color: $pumpkin);
  }

  &__success {
    @include base($bg: $success_color, $color: $white);
  }

  &__info {
    @include base($bg: $secondary_color, $color: $white);
  }
}

.close_button {
  z-index: 20;
  transition: all 250ms ease;
  margin-left: auto;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
    background-color: $black_squeeze;
    color: $secondary_color;
  }
}
