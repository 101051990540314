@import "../../variable";

.switch {
  height: 30px;
  .switch_label {
    position: relative;
    width: 68px;
    height: 30px;
    &::before {
      position: absolute;
      content: "No";
      width: 68px;
      height: 30px;
      border-radius: 15px;
      background: $border_color;
      color: $white;
      border: 0;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      padding-right: 12px;
      font-size: 12px;
      padding: 6px 9px 0 0;
      font-weight: 700;
      text-transform: uppercase;
      text-align: right;
      transition: background-color 0.2s ease-in-out;
    }
    &::after {
      position: absolute;
      content: "";
      width: 22px;
      height: 22px;
      background: $white;
      cursor: pointer;
      border-radius: 50%;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.4s ease;
    }
  }
  .switch_input {
    display: none;
    &:focus {
      ~ {
        .switch_label {
          &::before {
            box-shadow: none;
          }
        }
      }
    }
    &:checked {
      ~ .switch_label {
        &::before {
          content: "Yes";
          color: $white;
          border: 0;
          background-color: $success_color;
          box-shadow: none;
          outline: none;
          -webkit-appearance: none;
          padding-left: 12px;
          font-size: 12px;
          padding-top: 6px;
          padding: 6px 9px 0;
          font-weight: 700;
          text-transform: uppercase;
          text-align: left;
        }
        &::after {
          position: absolute;
          content: "";
          left: auto;
          left: 41px;
          transition: all 0.4s ease;
        }
      }
    }
    &:disabled + .switch_label {
      &::before,
      &::after {
        cursor: not-allowed;
      }
    }
  }
  .switch_input:focus:not(:checked) ~ .switch_label::before {
    border: 0;
  }
}
