@import '../../variable.scss';

.settings_table {
  height: 100%;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  border: 1px solid $primary_border_color;
  border-radius: 6px;
  .table {
    border: none;
    margin-bottom: 0;
    tr {
      .action_btns {
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      td {
        vertical-align: middle !important;
        &:first-child {
          width: 70px;
          padding-left: 20px !important;
        }
        &:last-child {
          text-align: right;
        }
      }
      &:hover {
        td {
          background-color: $black_squeeze;
        }
        &.table_header {
          td {
            background-color: transparent;
          }
        }
        .action_btns {
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      &.ordinaryRow {
        td {
          &:first-child {
            color: #02baf2;
          }
        }
      }
    }
  }
}
.shift_label {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
}
a {
  .user_link {
    color: #02baf2;
    cursor: pointer;
  }
}
.fleet-label {
  font-size: 10px;
  font-weight: 600;
  color: $hit_gray;
  text-transform: uppercase;
  margin-bottom: 0;
  position: absolute !important;
  top: -16px;
  transition: all ease 2s;
}

.service_list {
  padding: 0;
  margin: 0 18px;

  li {
    font-size: 16px;
    font-weight: 700;
    color: #364347;
    width: 100%;
    margin-top: -2px;
    max-width: 100%;
    word-break: break-word;
    line-height: 18px;
    margin-bottom: 15px;
  }
}
