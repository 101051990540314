@import 'variable.scss';
/* ====================
 Global SCSS start
 ==================== */

* {
  // -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  outline: none !important;
}

body,
html {
  color: $primary_color !important;
  height: 100%;
  font-size: 16px !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

.link,
a,
a:not([href]) {
  color: $secondary_color;
  text-decoration: none;
  &:focus,
  &:hover {
    color: $primary_color;
    text-decoration: underline;
    cursor: pointer;
  }
}

/* ====================
 Typographic start
 ==================== */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: $primary_color;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.29;
}

/* ====================
 Help class start
 ==================== */

.mt40 {
  margin-top: 40px;
}

.mt30 {
  margin-top: 30px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt5 {
  margin-top: 5px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.ml20 {
  margin-left: 20px;
}

.ml15 {
  margin-left: 15px;
}

.ml10 {
  margin-left: 10px;
}

.ml5 {
  margin-left: 5px;
}

.mr20 {
  margin-right: 20px;
}

.mr15 {
  margin-right: 15px;
}

.mr10 {
  margin-right: 10px;
}

.mr8 {
  margin-right: 8px;
}

.mr5 {
  margin-right: 5px;
}

.pt20 {
  padding-top: 20px;
}

.pt15 {
  padding-top: 15px;
}

.pt10 {
  padding-top: 10px;
}

.pt5 {
  padding-top: 5px;
}

.pt25 {
  padding-top: 25px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pb20 {
  padding-bottom: 20px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb5 {
  padding-bottom: 5px;
}

.pl20 {
  padding-left: 20px;
}

.pl15 {
  padding-left: 15px !important;
}

.pl10 {
  padding-left: 10px;
}

.pl3 {
  padding-left: 3px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr20 {
  padding-right: 20px;
}

.pr15 {
  padding-right: 15px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr10 {
  padding-right: 10px;
}

.pr45 {
  padding-right: 45px;
}

.pr5 {
  padding-right: 5px !important;
}

.pr_adjust {
  padding-right: 7.5px !important;
}

.pl_adjust {
  padding-left: 7.5px !important;
}

.no_mar {
  margin: 0 !important;
}

.no_pad {
  padding: 0 !important;
}

.p_l_n {
  padding-left: 0 !important;
}

.p_r_n {
  padding-right: 0 !important;
}

.max_width_85 {
  max-width: 85px !important;
  min-width: 85px !important;
}
.max_width_90 {
  max-width: 90px !important;
  min-width: 90px !important;
}
.max_width_70 {
  max-width: 70px !important;
  min-width: 70px !important;
}
.max_width_80 {
  max-width: 80px !important;
  min-width: 80px !important;
}
.max_width_100 {
  max-width: 100px !important;
  min-width: 100px !important;
}
.max_width_140 {
  max-width: 140px !important;
  min-width: 140px !important;
}
.max_width_125 {
  max-width: 125px !important;
  min-width: 125px !important;
}
.max_width_160 {
  max-width: 160px !important;
  min-width: 160px !important;
}
.max_height_80 {
  max-height: 80px !important;
}
.display_inline_block {
  display: inline-block !important;
}
.no_wrap {
  white-space: nowrap;
}
.mw_100 {
  min-width: 100px;
}

.gap5 {
  height: 5px;
}
.gap10 {
  height: 10px;
}
.gap15 {
  height: 15px;
}
.gap20 {
  height: 20px;
}
.gap25 {
  height: 25px;
}
.gap30 {
  height: 30px;
}

.no_float {
  float: none;
}

.d_b {
  display: block;
}

.inline_flex {
  display: inline-flex;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.breake_all {
  word-break: break-all;
}
.uppercase {
  text-transform: uppercase !important;
}

.positionRel {
  position: relative !important;
}

.font600 {
  font-weight: 600;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
}
/* ====================
 Form control start
 ==================== */
input[type='text']::placeholder,
input[type='search']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder,
input[type='textarea']::placeholder,
textarea::placeholder {
  font-size: 16px;
  font-style: normal;
  color: $border_color;
  font-weight: 400;
}

.form-control:focus {
  border-color: $border_color;
  box-shadow: none;
}

.btn {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  padding: 9px;
  line-height: 1.29;
  height: auto;
  transition: all 0.4s ease-in-out;
  color: $white;
  border: 0;
  width: 100%;
  text-transform: capitalize;

  &:active:focus,
  &:focus,
  &:hover,
  &:not(:disabled):not(.disabled):active:focus {
    color: $white;
    text-decoration: none;
  }

  &.button_black {
    background: #2f393c;
    color: #fff;
    font-size: 13px;
    font-weight: normal;
    padding: 0px 10px;
    border: 1px solid #2f393c !important;
    text-align: center;
    width: 107px;
    outline: none !important;
    box-shadow: 0 0 0 !important;
  }

  &.button_black:hover {
    border: 1px solid #2f393c !important;
  }
}

.button_primary {
  background-color: $tree_poppy;
  border-color: $tree_poppy;
  &.disabled {
    background-color: $tree_poppy;
    border-color: $tree_poppy;
    cursor: no-drop;
    pointer-events: none;
  }
  &:focus {
    background-color: $zest;
    border-color: $tree_poppy;
    box-shadow: none;
  }

  &:hover {
    background-color: $zest;
    border-color: $tree_poppy;
  }
  &:disabled {
    background-color: $zest;
    border-color: $tree_poppy;
  }

  &:active,
  &:active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $zest;
    border-color: $tree_poppy;
    box-shadow: none;
  }

  &:active:hover {
    background-color: $zest;
    border-color: $tree_poppy;
    color: $white;
    box-shadow: none;
  }
}

.button_secondary {
  background-color: $white;
  border: 1px solid $primary_border_color;
  color: $secondary_color;
  text-transform: capitalize;
  padding: 9px 10px;
  min-width: 172px;
  max-width: 172px;
  font-weight: 600;
  border-radius: 4px;
  &.black {
    color: $primary_color !important;
    text-transform: capitalize;
  }

  &:focus {
    background-color: $white;
    border: 1px solid $primary_border_color;
    color: $primary_color;
    box-shadow: none;
  }

  &:hover {
    background-color: $black_squeeze;
    border-color: $primary_border_color;
    color: $primary_color;
  }

  &:active,
  &:active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $black_squeeze;
    border-color: $primary_border_color;
    box-shadow: none;
    color: $primary_color;
  }

  &:active:hover {
    background-color: $black_squeeze;
    border-color: $primary_border_color;
    color: $primary_color;
    box-shadow: none;
  }

  &:disabled {
    background-color: $white;
    border: 1px solid $primary_border_color;
    color: $secondary_color;
    opacity: 0.4;
    pointer-events: none;
    cursor: none;
  }
}

.button_primary_blue {
  background-color: $secondary_color;
  border: 1px solid $secondary_color !important;
  color: $white;
  padding: 9px 10px;
  min-height: 40px;
  min-width: 172px;
  max-width: 172px;
  font-weight: 600;

  &.search_btn {
    position: absolute;
    right: 0;
    top: 0;
    min-height: 40px;
    max-width: 40px !important;
    min-width: 40px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:focus {
    background-color: $pacific_blue !important;
    border-color: $pacific_blue !important;
    box-shadow: none;
    color: $white !important;
  }

  &:hover {
    background-color: $pacific_blue !important;
    border-color: $pacific_blue !important;
    color: $white !important;
  }

  &:active,
  &:active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $pacific_blue !important;
    border-color: $pacific_blue !important;
    box-shadow: none;
    color: $white !important;
  }

  &:active:hover {
    background-color: $black_squeeze;
    border-color: $primary_border_color;
    color: $primary_color;
    box-shadow: none;
  }

  &:disabled {
    background-color: $secondary_color;
    border: 1px solid $secondary_color;
    color: $white;
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.button_danger {
  background: $danger_color;
  font-weight: 600;
  &:hover,
  &:focus,
  &:active {
    background-color: #d10909 !important;
    color: #fff !important;
    border-color: #d10909 !important;
    box-shadow: none !important;
  }
}

.small_btn {
  max-height: 30px;
  min-height: 30px;
  padding: 0;
}
.disable {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}

.loader {
  &.show {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.15);
  }
  &.hide {
    display: none;
  }
  img {
    width: 50px;
    position: absolute;
    top: 50%;
    margin: 0 auto;
  }
}

.badge {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $danger_color;
  color: $white;
  top: 7px;
  right: 12px;
  font-size: 10px;
  text-align: center;
  padding: 3px 0;
  font-weight: 600;
}

.shift_status {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: block;
  width: 100%;
  &.grey {
    color: $border_color;
  }
  &.green {
    color: $success_color;
  }
}

.modal_text {
  font-size: 16px;
  font-weight: 400;
  color: $hit_gray_a;
  margin-bottom: 10px;
}
.no_data_found {
  width: 100%;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $hit_gray_a;
  min-height: 80px !important;
  height: 80px !important;
}
.info_text {
  font-size: 24px;
  font-weight: 700;
  color: $primary_color;
}

#tooltip-top > .tooltip-inner {
  background-color: rgba(0, 0, 0, 0.9);
  color: $white;
  border-radius: 4px;
  font-size: 11px;
  padding: 5px 10px;
}

.btn_gap {
  margin-right: 12px !important;
}

/* ALerts */
.alerts_wrap {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  max-width: 780px;
  .alert {
    z-index: 1;
  }
}
/* Custom Alert Css Start */
.custom_success_alert {
  &.alert-success {
    background-color: $swans_down;
    color: $lochinvar;
    .alert-link {
      color: $lochinvar;
      font-weight: 700;
    }
    .close {
      color: $keppel_a;
      opacity: 1;
    }
  }
}
/* Custom Alert Css End */

/* Custom Checkboc Css Start */

.checkbox_area {
  cursor: pointer;
  font-size: 14px;
  color: $primary_color;
  max-width: 300px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin-top: 0;
    &:checked ~ label.form-check-label {
      background-color: $white;
    }
    &:checked ~ label.form-check-label::after {
      display: block;
    }
  }
  &.label-font-16 {
    .form-check-label {
      font-size: 16px;
    }
  }

  .form-check-label {
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    color: $primary_color;
    margin-left: 10px;
    line-height: 23px;
    margin-top: 3px;
    &:hover {
      cursor: pointer;
    }
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 7px;
      top: 7px;
      width: 7px;
      height: 12px;
      border: solid $secondary_color;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:before {
      content: '';
      border: 1px solid $border_color;
      padding: 6px 6px;
      position: absolute;
      left: 0px;
      border-radius: 3px;
      top: 4px;
      height: 20px;
      width: 20px;
    }
  }
}
/* Custom Checkboc Css End */

/* Custom Radio Css Start */

// .radiobtn_area {
//   cursor: pointer;
//   font-size: 14px;
//   color: $primary_color;
//   max-width: 300px;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   &:hover {
//     cursor: pointer;
//   }
//   .form-check-label {
//     text-transform: none;
//     font-size: 16px;
//     color: $primary_color;
//     margin-left: 6px;
//     line-height: 20px;
//     &:hover {
//       cursor: pointer;
//     }
//     &:after {
//       content: '';
//       position: absolute;
//       left: 0;
//       top: 0;
//       opacity: 1;
//       z-index: 21;
//       width: 20px;
//       height: 20px;
//       line-height: 20px;
//       background: url(../src/assets/images/ic_Radio_Checked.svg);
//       background-size: 100%;
//       border: 0;
//       padding: 0;
//       border-radius: 0;
//       display: none;
//     }
//     &:before {
//       content: '';
//       position: absolute;
//       left: 0;
//       top: 0;
//       opacity: 1;
//       z-index: 20;
//       width: 20px;
//       height: 20px;
//       line-height: 20px;
//       background: url(../src/assets/images/ic_Radio_Unchecked.svg);
//       background-size: 100%;
//       border: 0;
//       padding: 0;
//       border-radius: 0;
//     }
//   }
//   input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
//     margin-top: 0;
//     &:checked ~ label.form-check-label {
//       background-color: $white;
//     }
//     &:checked ~ label.form-check-label::after {
//       display: block;
//     }
//   }
// }
/* Custom Radio Css End */

/* Form Control Style Start */

.custom_form {
  .form-group {
    margin-bottom: 15px;
    width: 100%;

    .form-label {
      position: static;
      font-size: 10px;
      font-weight: 400;
      color: $hit_gray;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    textarea {
      resize: none;
    }
    .form-control {
      border-color: $primary_border_color;
      color: $primary_color;
      min-height: 40px;
      transition: all 250ms ease;
      &::placeholder {
        font-size: 16px;
        font-style: normal;
        color: $border_color;
        font-weight: 400;
      }
      &.disabled {
        background: $black_squeeze;
        border-color: $primary_border_color;
        opacity: 1;
        color: $border_color;
        pointer-events: inherit;
        cursor: not-allowed;
      }
      &.missing-field {
        border-color: $danger_color !important;
        &:active,
        &:focus,
        &:hover,
        &:link,
        &:visited {
          border-color: $danger_color !important;
        }
      }
    }

    .form_control_data {
      font-size: 16px;
      font-weight: 700;
      color: $primary_color;
      width: 100%;
      margin-top: -2px;
      max-width: 100%;
      word-break: break-word;
      line-height: 18px;
    }

    .custom_dropdown {
      .btn-primary {
        background-color: $white;
        border: 1px solid $primary_border_color;
        text-align: left;
        color: $primary_color;
        font-size: 16px;
        font-weight: 400;
        position: relative;
        min-height: 40px;
        &:hover,
        &:focus,
        &.active {
          box-shadow: none;
          outline: none;
          background-color: $white !important;
          border: 1px solid $primary_border_color !important;
          text-align: left;
          color: $primary_color !important;
        }
        &:focus,
        &.active {
          &.dropdown-toggle::after {
            background: url(../src/assets/images/ic_Dropdown_Blue.svg) 50% 50%
              no-repeat;
            background-size: contain;
            border-width: 0;
            border: 0;
          }
        }
        &.disabled {
          background: $black_squeeze;
          border-color: $primary_border_color;
          opacity: 1;
          color: $border_color;
          pointer-events: inherit;
          cursor: not-allowed;
        }
        &.missing_field {
          border-color: red;
        }
      }
      .dropdown-toggle::after {
        font-size: 0;
        background: url(../src/assets/images/ic_Dropdown_Main.svg) 50% 50%
          no-repeat;
        background-size: contain;
        width: 11px;
        height: 7px;
        position: absolute;
        top: 16px;
        right: 9px;
        border: 0;
      }
      .dropdown-menu {
        width: 100%;
        top: 8px !important;
        border-color: $border_color;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        padding: 5px 0;

        .dropdown-divider {
          margin: 3px 0;
        }
        .dropdown-item {
          padding-left: 10px;
          padding-right: 10px;
          &:hover {
            background-color: $secondary_color;
            color: $white;
          }
        }
      }
    }
  }
  &.box {
    padding: 0 15px 0 0;
    display: flex;
  }
}
.custom_dropdown .btn-primary:not(:disabled):not(.disabled):active,
.custom_dropdown .btn-primary:not(:disabled):not(.disabled).active,
.show > .custom_dropdown .btn-primary.dropdown-toggle,
.custom_dropdown .btn-primary:not(:disabled):not(.disabled):hover,
.custom_dropdown .btn-primary:not(:disabled):not(.disabled).hover,
.show > .custom_dropdown .btn-primary.dropdown-toggle,
.custom_dropdown .btn-primary:not(:disabled):not(.disabled):focus,
.custom_dropdown .btn-primary:not(:disabled):not(.disabled).focus,
.show > .custom_dropdown .btn-primary.dropdown-toggle {
  background-color: $white !important;
  border: 1px solid $primary_border_color !important;
  color: $primary_color !important;
}
.custom_dropdown .btn-primary:not(:disabled):not(.disabled):active:focus,
.custom_dropdown .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .custom_dropdown .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}
/* Form Control Style End */

/* Modal CSS Start */
.modal-backdrop {
  background-color: rgba(54, 67, 71, 0.6);
}

.custom_modal {
  .modal-dialog {
    display: flex;
    justify-content: center;
    width: 375px;

    .modal-content {
      margin-top: 0px;
      height: min-content;
      width: 600px;
      border: 1px solid $primary_border_color;
      box-shadow: 0 0 0;

      .modal-body {
        padding: 15px;
      }

      .modal-header {
        background-color: $black_squeeze;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .close {
          position: absolute;
          top: 22px;
          right: 20px;
          color: $hit_gray;
          text-shadow: 0 0 0;
          font-size: 24px;
          font-weight: 700;
          margin-left: 15px;
          opacity: 1;
          &:hover {
            color: $hit_gray;
          }
        }

        .modal-title {
          font-size: 24px;
          font-weight: 700;
          color: $primary_color;
          text-transform: capitalize;
        }
      }
      .modal-footer {
        border-top: 1px solid $primary_border_color;
        padding: 15px;
        > * {
          margin: 0;
        }
        // .btn {
        //   max-width: 85px;
        //   min-width: 85px;
        // }
      }
    }
  }
}

/* Modal CSS End */

/* Custom Tabs Css Start */

.custom_tabs {
  &.nav-tabs .nav-link {
    color: $primary_color;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 170px;
    text-align: center;
    margin-right: 15px;
    background-color: $white;
    border-radius: 0;
    border-color: $primary_border_color;
    border-bottom: none;
    padding: 9px 15px;
    &:hover,
    &:focus {
      border-color: $primary_border_color;
    }
  }
  &.nav-tabs .nav-link.active,
  &.nav-tabs .nav-item.show .nav-link {
    color: $white;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    min-width: 170px;
    text-align: center;
    margin-right: 16px;
    background-color: $primary_color;
    border-radius: 0;
  }
}

/* Custom Tabs Css End */

.custom_arrow_dropdown {
  .btn-primary {
    background-color: $white;
    border: 1px solid $primary_border_color;
    text-align: left;
    color: $nevada;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    width: 40px;
    height: 40px;
    &:hover,
    &:focus,
    &.active,
    &:not(:disabled):not(.disabled):active {
      box-shadow: none !important;
      outline: none !important;
      background-color: $black_squeeze;
      border-color: $primary_border_color;
    }
  }
  &.show {
    .btn-primary {
      &.dropdown-toggle {
        background-color: $black_squeeze;
        border-color: $primary_border_color;
      }
    }
  }
  .dropdown-toggle::after {
    content: '';
    border: solid $hit_gray;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-left: 6px;
    position: absolute;
    top: 14px;
  }
  .dropdown-menu {
    width: 100%;
    top: 5px !important;
    border-color: $border_color;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    padding: 0;

    .dropdown-item {
      padding-left: 10px;
      padding-right: 10px;
    }

    .dropdown-divider {
      margin: 3px 0;
    }
  }
}

/* Delete Overlay CSS Start */

.delete_overlay {
  .pop_overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    border: 2px solid $ecstasy;
    padding: 10px;
    z-index: 9999;
    overflow: hidden;
    &.dark {
      background: rgba(54, 67, 71, 0.95);
      border: 2px solid transparent;
    }
  }

  .pop_box {
    font-family: 'Source Sans Pro', sans-serif;
    width: 100%;
    max-width: 360px;
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $white;
    z-index: 9999;
    &.width210 {
      max-width: 210px;
    }
    .title {
      width: 100%;
      color: $white;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }
    .sub_title {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 16px;
      color: $black_squeeze;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
      max-width: 210px;
    }
    .note {
      width: 100%;
      p {
        color: $black_squeeze;
        font-size: 16px;
        line-height: 140%;
      }
    }

    .btn_outline_black {
      background: transparent;
      border: 1px solid $hit_gray;
      color: $black_squeeze;
      min-width: 105px;
      line-height: 120%;
      font-weight: 600;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      font-size: 16px;
      padding: 10px 20px;
      &:hover,
      &:focus,
      &:active {
        background: $outer_space;
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid $hit_gray;
      }
      &[disabled]:hover {
        background: transparent;
        border-color: $hit_gray;
        color: $primary_color;
      }
    }

    .btn_red {
      background-color: $danger_color;
      border: 1px solid $danger_color;
      color: $white;
      min-width: 105px;
      line-height: 120%;
      font-weight: 600;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      font-size: 16px;
      padding: 10px 20px;
      &:hover,
      &:focus,
      &:active {
        background: $danger_hover_color;
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid $danger_hover_color;
      }
      &[disabled]:hover {
        background: $danger_hover_color;
        border-color: $danger_hover_color;
        color: $primary_color;
      }
    }

    .min_width_170 {
      min-width: 170px;
    }
  }
}

/* Delete Overlay CSS End */

.content_area {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  top: 0;
  left: 0;
  margin-top: 0;
  // padding-left: 60px !important;
  background-color: $black_squeeze;

  .title_wrap {
    padding: 20px 15px 0px 0px;

    .main_title {
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: normal;
      color: $primary_color;
      margin-bottom: 5px;
    }
  }

  .tab_wrap {
    background-color: $white;
    height: 100%;
    padding: 25px 15px;
    width: 100%;

    .sub_title {
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      color: $nevada;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 5px;
      padding: 0;
    }
    .sub_title_info {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.25;
      color: $nevada;
    }
  }
}

.fleet_assets_table {
  max-height: 500px;
  overflow: auto;
  border-radius: 6px;
}

.bg_check_area {
  .bg_check_title {
    font-size: 12px;
    font-weight: 700;
    color: $nevada;
    text-transform: uppercase;
  }
  .bg_check_info {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.29;
    color: $primary_color;
    margin: 5px 0 10px 0;
  }
}

.profile_img {
  display: flex;
  justify-content: center;

  &.driver_profile_img {
    margin-top: 10px;
    .img_box {
      width: 90px;
      max-width: 90px;
      height: 90px;
      border-radius: 50%;
      background: $black_squeeze;
      display: inline-block;
      position: relative;
    }
  }
  .img_box {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: $black_squeeze;
    display: inline-block;
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 90px;
      background: url(../src/assets/images/ic_Camera.svg);
      background-position: 50% 50% !important;
      background-repeat: no-repeat !important;
      border-radius: 50%;
    }

    &.edit {
      position: relative;
      overflow: hidden;
      .icon {
        width: 100%;
        height: 90px;
        overflow: hidden;
        // background-image: none;
        background-size: auto !important;
      }

      .edit_driver {
        width: 100%;
        padding: 5px 0;
        font-weight: 700;
        cursor: pointer;
        position: absolute;
        left: 0;
        bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $white;
        text-transform: uppercase;
        font-size: 10px;
        z-index: 20;
        background: rgba(54, 67, 71, 0.7);
        text-align: center;
      }
    }

    input[type='file'] {
      width: 100%;
      height: 100%;
      z-index: 11;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 0px !important;
      color: transparent;
      color: $black;
      cursor: pointer;
    }
  }
}

.user_mask_view {
  width: 100%;
  align-items: center;
  text-align: center;
  height: 100%;
  vertical-align: middle;
  display: inline-grid;
  font-size: 32px;
  color: #5e696d;
  text-transform: uppercase;
}

/* Switch Button CSS Start */
.custom_switch {
  float: left;
  width: 68px;
  margin-right: 15px;

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border: 0;
  }

  .custom-control-label {
    &::before {
      content: 'No';
      width: 68px;
      height: 30px;
      border-radius: 15px;
      background: $border_color;
      color: $white;
      border: 0;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      padding-right: 12px;
      font-size: 12px;
      padding-top: 6px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: right;
      transition: background-color 0.2s ease-in-out;
    }

    &::after {
      width: 22px;
      height: 22px;
      background: $white;
      cursor: pointer;
      border-radius: 50%;
      left: auto;
      right: 9px;
      top: 8px;
      transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
    }
  }
  .custom-control-input {
    &:focus {
      ~ {
        .custom-control-label {
          &::before {
            box-shadow: none;
          }
        }
      }
    }
    &:checked {
      ~ {
        .custom-control-label {
          &::before {
            content: 'Yes';
            color: $white;
            border: 0;
            background-color: $success_color;
            box-shadow: none;
            outline: none;
            -webkit-appearance: none;
            padding-left: 12px;
            font-size: 12px;
            padding-top: 6px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: left;
          }
          &::after {
            right: auto;
            left: -7px;
            transition: all 0.4s ease;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -ms-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
          }
        }
      }
    }
  }
}

/* Switch Button CSS End */

/* Common Table CSS Start */

.round_border {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  color: $primary_color;
  font-size: 16px;
}
.round_border tr th,
.round_border tr td {
  border-right: none;
  border-bottom: 1px solid $primary_border_color;
  padding: 10px;
  border-top: none;
}
.round_border tr td.vehicle_assigned {
  padding: 5px 10px;
}
.round_border tr td span.vehicle_detail {
  display: block;
  font-size: 14px;
  margin-top: -5px;
}
.round_border tr:hover td {
  background-color: $black_squeeze;
  /* cursor: pointer; */
}
.round_border tr th:first-child,
.round_border tr td:first-child {
  border-left: 1px solid $primary_border_color;
  padding-left: 20px;
  width: 100px;
  max-width: 100px;
  word-break: break-all;
}
.round_border.hours_of_operation_table tr th:first-child,
.round_border.hours_of_operation_table tr td:first-child {
  width: 150px;
}
.round_border tr th:last-child,
.round_border tr td:last-child {
  border-right: 1px solid $primary_border_color;
}
.round_border tr th {
  padding: 15px 10px;
  background: $concrete;
  border-top: 1px solid $primary_border_color;
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
}
/* top-left border-radius */
.round_border tr:first-child th:first-child {
  border-top-left-radius: 6px;
}
/* top-right border-radius */
.round_border tr:first-child th:last-child {
  border-top-right-radius: 6px;
}
/* bottom-left border-radius */
.round_border tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}
/* bottom-right border-radius */
.round_border tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
.round_border .disable {
  color: $hit_gray;
}
.user_mask {
  width: 30px;
  height: 30px;
  float: left;
  line-height: 30px;
  background-color: $primary_border_color;
  font-size: 12px;
  font-weight: 600;
  color: $nevada;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
}
a.user_link:hover {
  color: $secondary_color;
}
.round_border tr.actions_hover td {
  position: relative;
}
.round_border tr.actions_hover td .actions {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #e1eef3;
  background: linear-gradient(
    90deg,
    rgba(225, 238, 243, 0.04) 0%,
    rgba(225, 238, 243, 0.04) 4%,
    rgba(225, 238, 243, 1) 21%,
    rgba(225, 238, 243, 1) 100%
  );
  padding: 10px;
  padding-left: 118px;
  width: auto;
}
.round_border tr.actions_hover:hover td .actions {
  opacity: 1;
}
.round_border .actions .actions_link {
  color: $secondary_color;
  padding: 5px 15px;
  background-color: transparent;
  max-height: 30px;
  white-space: nowrap;
}
.round_border .actions .actions_link:hover {
  background-color: $secondary_color;
  padding: 5px 15px;
  border-radius: 4px;
  color: $white !important;
  cursor: pointer;
  line-height: 20px;
  text-decoration: none;
}
.round_border .action_dropdown {
  width: auto;
  display: inline-block;
}
.round_border .action_dropdown button {
  color: $secondary_color;
  padding: 5px 15px;
  background-color: transparent;
  font-weight: 400;
}
.round_border .action_dropdown .dropdown-menu {
  top: 5px !important;
  border-color: $border_color;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  padding: 0;
}
.round_border .action_dropdown .dropdown-menu .dropdown-divider {
  margin: 3px 0;
}
.round_border .action_dropdown button.dropdown-toggle::after {
  content: '';
  border: solid $secondary_color;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 7px;
}
.round_border .action_dropdown button:hover.dropdown-toggle::after,
.round_border .action_dropdown button:focus.dropdown-toggle::after,
.round_border .action_dropdown button:active.dropdown-toggle::after {
  border: solid $white;
  border-width: 0 2px 2px 0;
}
.round_border .action_dropdown button:not(:disabled):not(.disabled):active,
.round_border .action_dropdown button:not(:disabled):not(.disabled).active,
.show > .round_border .action_dropdown button.dropdown-toggle {
  background-color: $secondary_color;
  padding: 5px 15px;
  border-radius: 4px;
  color: $white !important;
  outline: none;
  box-shadow: none;
  border-color: $secondary_color;
}
.round_border .action_dropdown button:hover,
.action_dropdown button:focus,
.action_dropdown button:active {
  background-color: $secondary_color;
  padding: 5px 15px;
  border-radius: 4px;
  color: $white !important;
  outline: none;
  box-shadow: none;
  border-color: $secondary_color;
}
.round_border .action_dropdown.show > button.dropdown-toggle,
.round_border .action_dropdown.show > button.dropdown-toggle:focus,
.round_border .action_dropdown.show > button.dropdown-toggle:active {
  background-color: $secondary_color;
  padding: 5px 15px;
  border-radius: 4px;
  color: $white !important;
  outline: none;
  box-shadow: none;
}
.round_border .action_dropdown.show > button.dropdown-toggle::after {
  border: solid $white;
  border-width: 0 2px 2px 0;
}
.round_border .action_dropdown .dropdown-item {
  font-size: 16px;
  color: $primary_color;
  padding-left: 10px;
  padding-right: 10px;
}

.round_border .pending_grey {
  color: #9daeb4;
  display: inline-block;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif !important;
}

.round_border .owed_grey {
  color: #4d6167;
  display: inline-block;
}

/* Common Table CSS End */

/* Custom Date picker Css Start */
.date_picker_area {
  position: relative;
  .date_icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    left: 12px;
    .fa-calendar-alt {
      color: $secondary_color;
      font-size: 16px;
    }
  }
}

.custom_date_picker {
  input {
    padding-left: 32px;
    height: 40px;
    border-color: $primary_border_color;
    cursor: pointer;
    transition: all 320ms ease;
    &:disabled {
      background-color: $black_squeeze;
      cursor: not-allowed;
      color: $border_color !important;
    }
    &:read-only {
      background-color: $white;
    }
  }
  .rdtPicker {
    border-radius: 5px;
    border-color: $border_color;
    margin-top: 10px;
    max-height: 400px;
    overflow-y: auto;
    min-width: 255px;
    max-width: 255px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    th {
      border-bottom: 0;
      &.rdtPrev {
        &:hover {
          border-radius: 3px;
        }
        &::before {
          content: '';
          display: block;
          margin: 11px auto;
          width: 9px;
          height: 9px;
          font-weight: 600;
          border-top: 3px solid $primary_color;
          border-left: 3px solid $primary_color;
          transform: rotate(-45deg);
        }
        span {
          display: none;
        }
      }
      &.rdtNext {
        &:hover {
          border-radius: 3px;
        }
        &::before {
          content: '';
          display: block;
          margin: 11px auto;
          width: 9px;
          height: 9px;
          font-weight: 600;
          border-top: 3px solid $primary_color;
          border-left: 3px solid $primary_color;
          transform: rotate(135deg);
        }
        span {
          display: none;
        }
      }
    }
    td {
      font-size: 14px;
      border: 2px solid $white;
      &.rdtToday {
        background-color: $gray_g;
        border-color: $white;
        color: $primary_color;
        border-radius: 3px;
        &::before {
          display: none;
        }
        &:hover {
          border-color: $white !important;
        }
      }
      &.rdtActive,
      &.rdtActive:hover {
        background-color: $secondary_color;
        border-color: $white !important;
        color: $white;
      }
      &.rdtDisabled {
        background: $black_squeeze;
        border-radius: 3px;
        color: $primary_color;
        font-weight: 400;
        opacity: 0.3;
        border: 2px solid $white !important;
        &:hover {
          background-color: $white;
          border: 2px solid $white !important;
          cursor: not-allowed;
        }
      }
      &.rdtDay {
        &:hover {
          border-radius: 3px;
          border: 2px solid $gray_b;
        }
      }
      &.rdtTimeToggle {
        font-size: 14px !important;
        color: $secondary_color !important;
        font-weight: 600 !important;
      }
    }
    .dow {
      color: $gray_h;
      font-size: 85%;
    }
  }
  .rdtSwitch {
    font-size: 14px;
    color: $secondary_color;
    font-weight: 600;
    width: auto;
    height: auto;
    cursor: pointer;
    &:hover {
      background: transparent !important;
    }
  }
}

/* Custom Date picker Css End */

.greyDivider {
  margin: 15px 0;
  width: 100%;
  height: 1px;
  background-color: $primary_border_color;
}

.greyNote {
  color: $hit_gray_a;
  font-size: 13px;
}

.alertDanger {
  width: 100%;
  max-width: 780px;
  background: $alert_danger;
  font-weight: 400;
  font-size: 16px;
  padding: 13px 15px;
  color: $danger_hover_color;
  margin-bottom: 10px;
  text-align: justify;
  &.warning {
    color: $pumpkin;
    background-color: $light_peach;
  }
  .successMessage {
    padding-left: 10px;
  }
}

.missing_field {
  border-color: $danger_hover_color !important;
}

.showScroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  border-radius: 10px;
  background: $primary_border_color;
  margin-right: 5px;
}
.showScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $hit_gray;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.text_capital {
  text-transform: capitalize;
}

.fsize14 {
  font-size: 14px;
}
.cap {
  text-transform: capitalize;
}
