@import '../../variable.scss';

.settings_table {
  height: 100%;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  border: 1px solid $primary_border_color;
  border-radius: 6px;
  .table {
    border: none;
    margin-bottom: 0;
    tbody {
      tr {
        td {
          padding: 5px 10px !important;
        }
      }
    }
    tr {
      .action_btns {
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      td {
        vertical-align: middle !important;
        &:first-child {
          width: 70px;
          padding-left: 20px !important;
        }
        &:last-child {
          text-align: right;
        }
      }
      &:hover {
        td {
          background-color: $black_squeeze;
        }
        &.table_header {
          td {
            background-color: transparent;
          }
        }
        .action_btns {
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      &.ordinaryRow {
        td {
          &:first-child {
            color: #02baf2;
          }
        }
      }
    }
  }
  .no_data_found {
    display: table-cell !important;
    text-align: center !important;
    min-height: 150px !important;
    height: 150px !important;
  }
}
.shift_label {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;

  &.offshift {
    color: #b2c6cd;
  }
}
.user_link {
  color: #02baf2;
  cursor: pointer;
}
.fleet-label {
  font-size: 10px;
  font-weight: 600;
  color: $hit_gray;
  text-transform: uppercase;
  margin-bottom: 0;
  position: absolute !important;
  top: -16px;
  transition: all ease 2s;
}

.custom_modal {
  .modal-dialog {
    width: 375px;
    // &.small_dialog {
    //   width: 375px;
    .modal-content {
      width: 375px !important;
      max-width: 375px;
      min-width: 375px;
      // }
    }
    .modal-footer {
      flex-wrap: nowrap;
      .btn {
        max-width: 100% !important;
      }
    }
  }
}

.driver_shift_text {
  text-align: center;
  font-size: 14px;
  color: #b2c6cd;
  top: 5px;
  position: relative;
  font-weight: 600;

  &.on_shift {
    color: #3eb5ac;
  }
}

.img-bg-cover {
  background-size: cover !important;
}
