.toastWrapper {
  position: fixed;
  z-index: 99999;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 500px;
}

.toast {
  $variants: (
    'error': (
      'bg': #bd362f,
      'color': white,
    ),
    'warning': (
      'bg': #f89406,
      'color': white,
    ),
    'info': (
      'bg': #2f96b4,
      'color': #31708f,
    ),
    'success': (
      'bg': #51a351,
      'color': white,
    ),
  );
  @mixin basicToast {
    flex-basis: 1% !important;
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    color: white;
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 5px;
    width: 100% !important;
    max-width: 780px !important;
  }
  @include basicToast;
  @each $variant, $styles in $variants {
    &_#{$variant} {
      @include basicToast;
      background-color: map-get(map-get($variants, $variant), 'bg') !important;
      color: map-get(map-get($variants, $variant), 'color') !important;
      .toastHeader {
        position: absolute;
        top: 0;
        right: 5px;
        background-color: transparent !important;
        padding: 0 !important;
        button {
          color: white !important;
        }
      }
      .toastBody {
        display: flex;
        align-items: flex-start;
        svg {
          font-size: 22px;
          position: relative;
          top: 2px;
          margin-right: 16px;
          margin-left: 5px;
        }
      }
    }
  }
}
.staticContainer {
  position: fixed;
  z-index: 1000;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  font-size: 24px;
}
